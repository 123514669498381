var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-spin",
    {
      attrs: {
        spinning: !_vm.wilayas && !_vm.statuts && !_vm.secteurs && !_vm.adherant
      }
    },
    [
      _c("DossierAdherant", {
        attrs: {
          "accept-terms-and-conditions": true,
          adherant: _vm.adherant,
          auto: _vm.getAuthorizationToken(),
          communes: _vm.communes,
          current: _vm.current,
          diplomes: _vm.diplomes,
          "etat-activites": _vm.etatActivites,
          "etat-actvite-modal-visible": _vm.etatActviteModalVisible,
          exercices: _vm.exercices,
          "is-admin": true,
          "list-dossier": _vm.listDossier,
          "new-cotisation-form-component-key":
            _vm.newCotisationFormComponentKey,
          "photo-list": _vm.adherentPhoto,
          processing: _vm.processing,
          "profile-pic-upload-url": _vm.profilePicUploadUrl,
          "saving-data": _vm.savingData,
          secteurs: _vm.secteurs,
          specialites: _vm.specialites,
          statuts: _vm.statuts,
          wilayas: _vm.wilayas,
          "wilayas-professional": _vm.wilayasProfessional
        },
        on: {
          addEtatActvite: function($event) {
            return _vm.addEtatActvite($event)
          },
          add_passif_reglement: function($event) {
            return _vm.addPassifReglement($event)
          },
          clear_profile_pic: _vm.handleClearProfilePic,
          deleteEtatActivite: function($event) {
            return _vm.deleteEtatActivite($event)
          },
          deleteEtatActiviteFile: function($event) {
            return _vm.deleteEtatActiviteFile($event)
          },
          delete_dossier: function($event) {
            return _vm.deleteDossier($event)
          },
          getUser: _vm.getUser,
          is_dossier_complete_change: function($event) {
            return _vm.updateDossierCompleteState($event)
          },
          next: _vm.handleNextStepClick,
          previous: _vm.handlePreviousStepClick,
          refresh_list_dossier: _vm.getUser,
          save: function($event) {
            return _vm.save($event)
          },
          updateEtatActvite: function($event) {
            return _vm.updateEtatActvite($event)
          },
          update_profile_pic: _vm.updateProfilePic,
          updated_soumission_state: function($event) {
            return _vm.updateAdherentSoumissionState($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }