<template>
    <a-spin :spinning="!wilayas && !statuts && !secteurs && !adherant">
        <DossierAdherant
                :accept-terms-and-conditions="true"
                :adherant="adherant"
                :auto="getAuthorizationToken()"
                :communes="communes"
                :current="current"
                :diplomes="diplomes"
                :etat-activites="etatActivites"
                :etat-actvite-modal-visible="etatActviteModalVisible"
                :exercices="exercices"
                :is-admin="true"
                :list-dossier="listDossier"
                :new-cotisation-form-component-key="newCotisationFormComponentKey"
                :photo-list="adherentPhoto"
                :processing="processing"
                :profile-pic-upload-url="profilePicUploadUrl"
                :saving-data="savingData"
                :secteurs="secteurs"
                :specialites="specialites"
                :statuts="statuts"
                :wilayas="wilayas"
                :wilayas-professional="wilayasProfessional"
                @addEtatActvite="addEtatActvite($event)"
                @add_passif_reglement="addPassifReglement($event)"
                @clear_profile_pic="handleClearProfilePic"
                @deleteEtatActivite="deleteEtatActivite($event)"
                @deleteEtatActiviteFile="deleteEtatActiviteFile($event)"
                @delete_dossier="deleteDossier($event)"
                @getUser="getUser"
                @is_dossier_complete_change="updateDossierCompleteState($event)"
                @next="handleNextStepClick"
                @previous="handlePreviousStepClick"
                @refresh_list_dossier="getUser"
                @save="save($event)"
                @updateEtatActvite="updateEtatActvite($event)"
                @update_profile_pic="updateProfilePic"
                @updated_soumission_state="updateAdherentSoumissionState($event)"
        />
    </a-spin>
</template>

<script>
    import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
    import moment from "moment";
    import _ from "lodash-es";
    import DossierAdherant from "@/components/common/dossier_adherant/Index";

    const PHOTO_UPLOAD_ADHERENTS_URL1 = `/user/upload-profile-picture`;

    export default {
        name: "AdherentEditor",
        beforeCreate() {},
        created() {
            this.clearSelectedAdherent();
            if (this.editMode) {
                this.getAdherentById(this.$route.params["id"]);
            }
            this.getAdditioanlData();
        },
        components: {
            DossierAdherant,
        },
        mounted() {
            if (this.editMode && this.adherant) {
                this.adherant.dtn = moment(this.adherant.dtn);
            }
        },
        data() {
            return {
                confirmDirty: false,
                autoCompleteResult: [],
                sexe: 0,
                current: 0,
                id: "",
                processing: false,
                etatActviteModalVisible: false,
                savingData: false,
                newCotisationFormComponentKey: 1,
            };
        },
        computed: {
            profilePicUploadUrl() {
                return this.adherant && this.adherant.id
                    ? this.generatePicUploadUrl(this.adherant.id)
                    : "";
            },
            ...mapGetters({
                listDossier: "getSelectedAdherentListOfListDossier",
                adherentPhoto: "getSelectedAdherentListOfProfilePhoto",
                etatActivites: "getSelectedAdherentEtatActivites"
            }),
            ...mapState({
                adherant: (state) => state.adherents.selected_adherent,
                secteurs: (state) => state.secteurs.secteurs,
                statuts: (state) => state.statuts.statuts,
                specialites: (state) => state.specialites.specialites,
                communes: (state) => state.communes.communes,
                diplomes: (state) => state.diplomes.diplomes,
                exercices: (state) => state.exercices.exercices,
                wilayas: (state) => state.wilayas.wilayas,
                wilayasProfessional: (state) => state.wilayas.organisation_wilayas,
                base_url: (state) => state.auth.base_url,
            }),
        },
        methods: {
            startProcessing() {
                this.processing = true;
            },
            stopProcessing() {
                this.processing = false;
            },
            generatePicUploadUrl() {
                return `${this.base_url}${PHOTO_UPLOAD_ADHERENTS_URL1}`;
            },
            getAdditioanlData() {
                this.$store.cache.dispatch("fetchWilayas");
                this.$store.cache.dispatch("fetchOrganisationWilayas");
                this.$store.cache.dispatch("fetchSpecialites");
                this.$store.cache.dispatch("fetchCommunes");
                this.$store.cache.dispatch("fetchExercices");
                this.$store.cache.dispatch("fetchDiplomes");
                this.$store.cache.dispatch("fetchSecteurs");
            },
            getUser() {
                this.getAdherentById(this.adherant.id);
            },
            handleClearProfilePic() {
                this.clearProfilePic(this.adherant.id);
            },
            displayErrors(error) {
                if (error.response.status === this.HTTP_INVALID) {
                    this.$_throwAnError("L'email existe deja ");
                    return;
                }
                let _that = this;
                _.forEach(error.response.data.errors, function (value, key) {
                    _that.$_throwAnError(value);
                });
            },
            updateProfilePic() {
                if (this.editMode || this.adherant) {
                    this.getAdherentById(this.adherant.id);
                }
            },
            save(adherant) {
                this.savingData = true;
                if (this.adherant === null) {
                    this.storeAdherent({...adherant})
                        .then(() => this.handleNextStepClick())
                        .catch((error) => this.displayErrors(error))
                        .finally(() => this.savingData = false);
                    return;
                }
                for (var key in this.adherant) {
                    if (this.adherant.hasOwnProperty(key) && moment.isMoment(this.adherant[key])) {
                        this.adherant[key] = this.formatDate(this.adherant[key], "YYYY-MM-DD");
                    }
                }
                this.updateAdherent({...this.adherant, ...adherant})
                    .then(() => this.handleNextStepClick())
                    .catch((error) => this.displayErrors(error))
                    .finally(() => this.savingData = false);
            },
            addPassifReglement(passifReglement) {
                this.startProcessing();
                this.storePassifReglement(passifReglement)
                    .then(() => this.getAdherentById(this.adherant.id))
                    .finally(() => this.stopProcessing());
            },
            addEtatActvite(etatActivite) {
                this.startProcessing();
                this.storeEtatActvite(etatActivite)
                    .then(() => {
                        this.getAdherentById(this.adherant.id)
                            .then(() =>this.hideEtatActiviteModel());
                    })
                    .catch((er) => {
                        if (er && er.response.data) {
                            this.displayErrors(er);
                        }
                    })
                    .finally(() => this.stopProcessing());
            },
            updateEtatActvite(etatActivite) {
                this.startProcessing();
                this.updateUserEtatActvite(etatActivite)
                    .then(() => {
                        this.hideEtatActiviteModel();
                        this.getAdherentById(this.adherant.id);
                    })
                    .catch((er) => {
                        if (er && er.response.data) {
                            this.displayErrors(er);
                        }
                    })
                    .finally(() => this.stopProcessing());
            },
            deleteEtatActivite(id) {
                this.startProcessing();
                this.deleteUserEtatActvite(id)
                    .then(() => this.getAdherentById(this.adherant.id))
                    .catch((er) => {
                        if (er && er.response.data) {
                            this.displayErrors(er);
                        }
                    })
                    .finally(() => this.stopProcessing());
            },
            deleteEtatActiviteFile(id) {
                this.startProcessing();
                this.deleteUserEtatActiviteFile(id)
                    .then(() => this.getAdherentById(this.adherant.id))
                    .catch((er) => {
                        if (er && er.response.data) {
                            this.displayErrors(er);
                        }
                    })
                    .finally(() => this.stopProcessing());
            },
            updateAdherentSoumissionState() {
                this.startProcessing();
                this.updateSoumissionState(this.adherant)
                    .then(() => {
                        this.$_showSuccessMessage(
                            "Vos modifications ont bien été enregistrées"
                        );
                    })
                    .catch((er) => {
                        if (er && er.response.data) {
                            this.displayErrors(er);
                        }
                    })
                    .finally(() => this.stopProcessing());
            },
            updateDossierCompleteState(acceptTermsAndConditions) {
                this.startProcessing();
                this.updateAdherent({id: this.adherant.id})
                    .then(() => {
                        this.$_showSuccessMessage(
                            "Vos modifications ont bien été enregistrées"
                        );
                    })
                    .catch((er) => {
                        if (er && er.response.data) {
                            this.displayErrors(er);
                        }
                    }).finally(() => this.stopProcessing());
            },
            deleteDossier(id) {
                this.deleteAdherentDossierRequis({
                    user_id: this.adherant.id,
                    file_id: id,
                })
                    .then(() => {
                        this.getUser();
                        this.$_showSuccessMessage("Votre document est supprimer");
                    })
                    .catch(() => {
                        this.$_throwAnError();
                    });
            },
            hideEtatActiviteModel(){
                this.etatActviteModalVisible = false;
            },
            handlePreviousStepClick() {
                this.current--;
            },
            handleNextStepClick() {
                this.current++;
            },
            ...mapActions({
                getWilayas: "fetchWilayas",
                getAdherentById: "fetchAdminAdherentsById",
                getSpecialites: "fetchSpecialites",
                getCommunes: "fetchCommunes",
                getDiplomes: "fetchDiplomes",
                updateSoumissionState: "updateAdherentsSoumission",
                updateAdherent: "updateAdminAdherent",
                storeAdherent: "storeAdminAdherent",
                add: "storeAdminAdherent",
                storeEtatActvite: "storeAdminUserEtatActvite",
                storePassifReglement: "storeAdminPassifReglement",
                refreshListDossier: "fetchAdminAdherentsListDossierById",
                deleteAdherentDossierRequis: "destroyAdherentDossierRequis",
                deleteUserEtatActvite: "destroyAdminUserEtatActvite",
                deleteUserEtatActiviteFile: "destroyUserEtatActiviteFile",
                updateUserEtatActvite: "updateAdminUserEtatActvite",
                callPdfGenerator: "callPdfGenerator",
                clearProfilePic: "clearAdherentProfilePic",
            }),
            ...mapMutations(["clearSelectedAdherent"]),
        },
    };
</script>
